import React from "react";
import "../StyleSheet/ModalStyle.css";
import Modal from "react-bootstrap/Modal";
import tick from "../Assets/tick.png";

export default function SuccessModal(props) {
  return (
    <Modal
      show={props.modalShow}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <div className="successCardContainer">
        <div className="successCardHeading">Congratulations !</div>
        <img className="successCardImg" src={tick} />
        <div className="successCardDescContainer">
          <div className="successCardDesc">
            <span style={{ fontWeight: "bolder", textTransform: "uppercase" }}>
              {props.clientName}
            </span>
            {props.text}
          </div>
        </div>
        {props.text ? (
          <button className="errorCardButton" onClick={props.onOK}>
            OK
          </button>
        ) : null}
      </div>
    </Modal>
  );
}

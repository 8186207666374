import React, { useEffect, useRef, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import "../StyleSheet/PDFSign.css";
import Draggable from "react-draggable";
import img from "../Assets/waveImg1.png";
import { PDFDocument } from "pdf-lib";
import ArrowR from "../Assets/right.png";
import ArrowL from "../Assets/left.png";
import { decode as base64_decode } from "base-64";
import { fetchGet, fetchPost } from "../Utils/FetchApis";
import ApiNames from "../Constants/ApiNames";
import crossImg from "../Assets/cross.jpg";
import ProccessingModal from "./ProccessingModal";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import background from "../Assets/Ad.webp";


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  "pdfjs-dist/build/pdf.worker.min.js",
  import.meta.url
).toString();
const options = {
  cMapUrl: "cmaps/",
  standardFontDataUrl: "standard_fonts/",
};
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
// let files = require("../corporate183.pdf");
let sigPad = {};


const PDFSign = (props) => {
  const pdfContainerRef = useRef(null);
  const [updatedArray, setUpdatedArray] = useState([]);
  const [file, setFile] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [numPages, setNumPages] = useState(null);
  const [signatureCount, setSignatureCount] = useState(0);
  const [currentCorrdinate, setCurentCorrdinate] = useState([]);
  const [id, setId] = useState(0);
  const [processingShow, setProcessingShow] = useState(true);
  const [errText, setErrText] = useState("");
  const [ErrFailure, setErrFailure] = useState(false);
  const [processingText, setProcessingText] = useState("Loading PDF Forms.");
  const [subSuccess, setSubSuccess] = useState(false);
  const [retry, setRetry] = useState(false);


  useEffect(() => {
    if (props.token) {
      const queryString = window.location.search;
      const queryParams = new URLSearchParams(queryString);
      const idValue = queryParams.get("formTypeId");
      const decodeValue = base64_decode(idValue);
      setId(decodeValue);
      if(decodeValue){
        onGetFormCoordinates(decodeValue);
      }else{
        setProcessingShow(false);
        setErrText("Oops.. Something went wrong. Please try again.");
        setErrFailure(true);
      }
    }
  }, [props.token, retry]);

  const onGetFormCoordinates = async (id) => {
    try {
      let response = await fetchGet(
        ApiNames.getFormCoordinates + "?id=" + id,
        props.token
      );
      if (response.status === 0) {
        setSignatureCount(response.response.signatureCount);
        setFile(response.response.url);
        setCurentCorrdinate(response.response.current);
        setTimeout(() => {
          setProcessingShow(false);
        }, 1000);
      } else if (response.status === 1) {
        setProcessingShow(false);
        setErrText(response.response);
        setErrFailure(true);
      } else {
        setProcessingShow(false);
        setErrText("Oops.. Something went wrong. Please try again.");
        setErrFailure(true);
      }
    } catch (err) {
      setProcessingShow(false);
      setErrText("Oops.. Something went wrong. Please try again.");
      setErrFailure(true);
    }
  };

  const handlePdfClick = (event) => {
    let _updatedArray = [...updatedArray];
    if (_updatedArray.length < signatureCount) {
      const { clientX, clientY } = event;
      const pdfContainer = document.querySelector(".pdf-container");

      if (pdfContainer) {
        const { top, left } = pdfContainer.getBoundingClientRect();
        const x = clientX - left;
        const y = clientY - top;
        _updatedArray.push({ x: x, y: y, page: pageNumber-1 });
        setUpdatedArray(_updatedArray);
      }
    }
  };
 
  function onDocumentLoadSuccess({ numPages }) {
    // setProcessingShow(false);
    setNumPages(numPages);
    setPageNumber(1);
  }

  function addChangePage(offset) {
    if (numPages !== null) {
      if (pageNumber < numPages) {
        setPageNumber((prevPageNumber) => prevPageNumber + offset);
      }
    }
  }
  function negChangePage(offset) {
    if (pageNumber > 1) {
      setPageNumber((prevPageNumber) => prevPageNumber + offset);
    }
  }

  function previousPage() {
    negChangePage(-1);
  }

  function nextPage() {
    addChangePage(1);
  }

  const onhandleSubmit = async () => {
    let data = {
      id: id,
      new: updatedArray,
    };
    setProcessingText("Submiting Documents.");
    setProcessingShow(true);
    try {
      let response = await fetchPost(
        ApiNames.saveNewCoordinates,
        props.token,
        data
      );
      if (response.status === 0) {
        setSubSuccess(true);
        setProcessingShow(false);
      } else {
        setProcessingShow(false);
        setErrText("Oops.. Something went wrong. Please try again.");
        setErrFailure(true);
      }
    } catch (err) {
      setProcessingShow(false);
      setErrText("Oops.. Something went wrong. Please try again.");
      setErrFailure(true);
    }
  };
  const onCrossClick = (event, index) => {
    event.stopPropagation();
    let _updatedArray = [...updatedArray];
    _updatedArray.splice(index, 1);
    setUpdatedArray(_updatedArray);
  };
  const Retry = () => {
    setErrFailure(false);
    setUpdatedArray([])
    setRetry(!retry);
  };
  const onOK = () => {
    setSubSuccess(false);
  };

  return (
    <div>
      <ProccessingModal
        modalShow={processingShow}
        processingText={processingText}
      />
      <ErrorModal modalShow={ErrFailure} Retry={Retry} errText={errText} />
      <SuccessModal
        modalShow={subSuccess}
        clientName="Successfully saved coordinates"
        onOK={onOK}
        text=""
      />
      {subSuccess === false? (
        <>
        {processingShow===false? (<>
          {file && (
            <>
            <div style={{display:"flex",alignSelf:"center",alignItems:"center",flexDirection:"column"}}>
              <div
                ref={pdfContainerRef}
                className="pdf-container"
                onClick={(e) => handlePdfClick(e)}
              >
                <Document
                  className="DocPDFs"
                  file={file}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {updatedArray.length > 0 ? (
                    <div style={{ position: "absolute", zIndex: 1 }}>
                      {updatedArray.map((e, index) => {
                        return (
                          e.page === pageNumber-1 && (
                            <div style={{ position: "absolute", zIndex: 1 }}>
                              <Draggable
                                handle=".handle"
                                position={{ x: e.x, y: e.y }}
                                scale={1}
                                disabled={true}
                              >
                                <div style={{ display: "flex" }}>
                                <div className="imgContainer">
                                  <img
                                    className="handle"
                                    src={img}
                                    height={50}
                                    width={50}
                                  />
                                  </div>
                                    <img
                                      src={crossImg}
                                      className="cross"
                                      onClick={(e) => onCrossClick(e, index)}
                                    />
                                </div>
                              </Draggable>
                            </div>
                          )
                        );
                      })}
                    </div>
                  ) : (
                    <div style={{ position: "absolute", zIndex: 1 }}>
                      {currentCorrdinate.map((e) => {
                        return (
                          e.page === pageNumber-1 && (
                            <div style={{ position: "absolute", zIndex: 1 }}>
                              <Draggable
                                axis="both"
                                handle=".handle"
                                position={{ x: e.x, y: e.y }}
                                grid={[25, 25]}
                                scale={1}
                                disabled={true}
                              >
                                <img
                                  className="handle"
                                  src={img}
                                  height={50}
                                  width={50}
                                />
                              </Draggable>
                            </div>
                          )
                        );
                      })}
                    </div>
                  )}

                  <Page
                    scale={1}
                    pageNumber={pageNumber}
                    canvasBackground="lightgrey"
                    className="pageBackground"
                  />
                </Document>
              </div>
              <div className="containerButton">
                <div className="containerPagination">
                  <img src={ArrowL} width="20px" onClick={previousPage} />

                  <div className="pagec">
                    Page {pageNumber || (numPages ? 1 : "--")} /{" "}
                    {numPages || "--"}
                  </div>

                  <img src={ArrowR} width="20px" onClick={nextPage} />
                </div>
                <div style={{color: "#2f4050",fontWeight:"500" }} >
                  Signature {updatedArray.length} of {signatureCount}
                </div>
                {updatedArray.length === signatureCount ? (
                  <div onClick={onhandleSubmit} className="saveButton">
                    submit
                  </div>
                ) : (
                  <div className="saveButtonInactive">submit</div>
                )}
              </div>
            </div>
            </>
          )}
          </>):null}
        </>
      ) : null}
    </div>
  );
};

export default PDFSign;

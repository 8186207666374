import React from 'react'
import '../StyleSheet/ModalStyle.css'
import Modal from "react-bootstrap/Modal";
import Cross from '../Assets/Cross.webp'

export default function ErrorModal(props) {
  return (
    <Modal
    show={props.modalShow}
    onHide={props.handleModalHide}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
      <div className="successCardContainer">
        <div className="errorCardHeading">
          ERROR
        </div>
        <img className="successCardImg" src={Cross} />
        <div className="errorCardDesc">{props.errText}</div>
        <button className="errorCardButton" onClick={props.Retry} >Retry</button>
      </div>
  </Modal>
  )
}

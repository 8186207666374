export const fetchPostAuth = async (apiName) => {
    let response = await fetch(`${process.env.REACT_APP_Base_URL}${apiName}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        "username": process.env.REACT_APP_UserName,
        "password": process.env.REACT_APP_Password
    }),
    });
    
    let jsonResponse = await response.json();
  
    return jsonResponse;
  };


  
export const fetchGet = async (apiName,token) => {
  let response = await fetch(`${process.env.REACT_APP_Base_URL}${apiName}`, {
    method: "GET",
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
  });
  let jsonResponse = await response.json();
  return jsonResponse;
}


export const fetchPostSaveSign = async (apiName,id,pdf,token) => {
  let response = await fetch(`${process.env.REACT_APP_Base_URL}${apiName}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      "requestId": id,
      "documents": pdf
  }),
  });
  
  let jsonResponse = await response.json();

  return jsonResponse;
};



export const fetchPostSaveSubscription = async (apiName,id,token) => {
  let response = await fetch(`${process.env.REACT_APP_Base_URL}${apiName}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify({
      "clientId": id,
  }),
  });
  
  let jsonResponse = await response.json();

  return jsonResponse;
};


export const fetchPost = async (apiName,token,data) => {
  let response = await fetch(`${process.env.REACT_APP_Base_URL}${apiName}`, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`,
    },
    body: JSON.stringify(data)
  });
  
  let jsonResponse = await response.json();

  return jsonResponse;
};
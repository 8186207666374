import React from 'react'
import '../StyleSheet/ModalStyle.css'
import Modal from "react-bootstrap/Modal";
import loader from '../Assets/Processing.gif'

export default function ProccessingModal(props) {
  return (
    <Modal
    show={props.modalShow}
    size="md"
    aria-labelledby="contained-modal-title-vcenter"
    centered
  >
      <div className="successCardContainer">
        <div className="successCardHeading">
        Processing !
        </div>
        <img className="proccessCardImg" src={loader} />
        <div className="proccessCardDesc">{props.processingText}<br/>please wait...</div>
      </div>
  </Modal>
  )
}

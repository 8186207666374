import React, { useEffect, useState } from "react";
import PDF from "./Components/PDF";
import { fetchPostAuth } from "./Utils/FetchApis";
import ApiNames from "./Constants/ApiNames";
import ErrorModal from "./Components/ErrorModal";
import { Navigate, Route, Routes } from "react-router-dom";
import PDFSign from "./Components/PDFSign";

export default function App() {
  const [token, setToken] = useState(null);
  const [ErrFailure, setErrFailure] = useState(false);
  useEffect(() => {
    authJWT();
  }, []);
  const Retry = () => {
    setErrFailure(false);
    authJWT();
  };

  const authJWT = async () => {
    try {
      let response = await fetchPostAuth(ApiNames.generateJWT);
      if (response.jwttoken) {
        setToken(response.jwttoken);
      } else {
        setErrFailure(true);
      }
    } catch (err) {
      setErrFailure(true);
    }
  };
  return (
    <>
      <ErrorModal
        modalShow={ErrFailure}
        Retry={Retry}
        errText="Something went wrong. Please try again."
      />
       <Routes>
          <Route path="/*" element={<PDF token={token} />}></Route>
          <Route path="/editCoordinates" element={<PDFSign token={token}/>}></Route>
       </Routes>
      
    </>
  );
}

// import React, { useEffect, useState } from "react";
// import PDF from "./Components/PDF";
// import { fetchPostAuth } from "./Utils/FetchApis";
// import ApiNames from "./Constants/ApiNames";
// import ErrorModal from "./Components/ErrorModal";

// export default function App() {
//   const [token, setToken] = useState(null);
//   const [ErrFailure, setErrFailure] = useState(false);
//   const [processingShow, setProcessingShow] = useState(true);
//   useEffect(() => {
//     authJWT();
//   }, []);

//   const authJWT = async () => {
//     try {
//       let response = await fetchPostAuth(ApiNames.generateJW);
//       if (response !== null) {
//         console.log(response);
//         setToken(response.jwttoken);
//       } else {
//         // alert("Authentication failed. Please try again.");
//         setErrFailure(true);
//         setProcessingShow(false);
//       }
//     } catch (err) {
//       // alert("Authentication failed: " + err);
//       setErrFailure(true);
//       setProcessingShow(false);
//     }
//   };
//   return (
//     <>
//       {ErrFailure ? (
//         <ErrorModal
//           modalShow={ErrFailure}
//           Retry=""
//           errText="Authentication failed. Please try again."
//         />
//       ) : (
//         <PDF token={token} processingShow={processingShow} />
//       )}
//     </>
//   );
// }

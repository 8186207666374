const ApiNames = {
  generateJWT:'/generateToken',
  getRequest:'/request',
  saveSignedFile:'/saveSignedFile',
  getBrands:'/getBrands',
  saveSubscriptions:'/saveSubscriptions',
  getPushedRequest:'/getPushedRequest',
  getFormCoordinates:"/coordinates/getFormTypeData",
  saveNewCoordinates:"/coordinates/saveNewCoordinates"
};

export default ApiNames;
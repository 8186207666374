import React from "react";
import "../StyleSheet/listStyle.css";
export default function List(props) {
  return (
    <div style={{ display: "flex",width:"90%",overflowX:"auto",marginBottom:'20px',justifyContent:'flex-start',whiteSpace:'nowrap' }}>
      {props.formLength !== null &&
        props.formLength.map((e, index) => {
          return (
            <div key={index} className={props.listIndex === index ? "listContainer": "listContainerInactive"} onClick={()=>props.listIndex === index ? null: props.pdfListHandler(index)}>
              {e.name}
            </div>
          );
        })}
    </div>
  );
}


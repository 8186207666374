import React from "react";
import Modal from "react-bootstrap/Modal";
import "../StyleSheet/BrandStyle.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSquareCheck } from "@fortawesome/free-solid-svg-icons";
import { faSquare } from "@fortawesome/free-regular-svg-icons";
export default function Brand(props) {
  return (
    <div>
      <div className="brandCardBodyContainer">
        <div className="brandCardBodyHeading">Subscribe</div>
        <div className="brandCardBodyDesc">
          Subscribe to the promotional messages from brands.
        </div>
        <div className="brandCardBodySubDesc">
          Protax will send these messages on client's behalf,
          <br className="br" /> please share your consent.
        </div>
        {/* <div onClick={props.onListCheckAllHandler} className="allButton">
          <input
            type="checkbox"
            className="checkBtnDot"
            checked={props.flagAll}
          />{" "}
          Select ALL
        </div> */}
        {/* brand */}
        {/* <div
          style={{ marginTop: "20px", height: "300px", overflowY: "scroll" }}
        >
          {props.brandNames.map((e, index) => {
            return (
              <div
                key={e.id}
                className="brandNameContainer"
                onClick={() => props.onListcheckHandler(index)}
              >
                <div style={{ display: "flex" }}>
                  <input
                    type="checkbox"
                    className="checkBtnDot"
                    checked={props.brandStatus[index]}
                  />
                  <div className="brandNameHeading">{e.name}</div>
                </div>
                {e.iconUrl !== null ? (
                  <img src={e.iconUrl} className="brandNameImg" />
                ) : null}
              </div>
            );
          })}
        </div> */}
        <div className="checkboxContainer">
          <div className="checkDesc" onClick={props.oncheckHandler}>
            {props.buttonhandel ? (
              <FontAwesomeIcon
                size="xl"
                style={{ color: "#1E3050", marginRight: "5px" }}
                icon={faSquareCheck}
              />
            ) : (
              <FontAwesomeIcon
                style={{ marginRight: "5px" }}
                size="xl"
                icon={faSquare}
              />
            )}
            I agree to receive all promotional/transactional messages with an
            understanding that pro tax can send me any brand”s sales related
            messages of any company & If I donot want to receive the messages I
            can stop them by replying STOP to a promotional message.
          </div>
        </div>
        <div className="brandButtonContrainers">
          {/* <div className="brandCardButtonCancel" onClick={props.cancelBrand}>
            Cancel
          </div> */}
          {/* {props.buttonhandel ? ( */}
            <button className="brandCardButton" onClick={props.buttonhandel ?props.onhandelSubscribe:props.onhandelcancelBrand}>
              Submit
            </button>
          {/* // ) : (
          //   <button className="brandCardButton" onClick={props.cancelBrand}>Submit</button>
          // )} */}
        </div>
      </div>
    </div>
  );
}
